import React, { useEffect, useRef, useState } from "react"
import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { useDispatch, useSelector } from "react-redux"
import { Card } from "react-bootstrap"
import { CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import {
  DetailText,
  InlineText,
  NoteView,
  RegionSelect,
  RowText,
  Status,
  TableSkeleton
} from "../../../../../component/index.jsx"
import BootstrapTable from "react-bootstrap-table-next"
import { formatterRowComponent } from "../../../../../component/atoms/Tabel/index.jsx"
import { Popup, PopupQuestion } from "../../../../../component/atoms/Popup/index.jsx"
import {
  activitySendToErp,
  confirmationSeasonPlanting,
  getDetailHarvest,
  updateAreaActivityImage
} from "../../../../../redux/actions/AreaAction.jsx"
import { timeStampToDate } from "../../../../helpers/DateHelper.js"
import {
  checkDateRangeValue,
  checkValue,
  toMeter,
  toRupiah,
  toThousandConvert
} from "../../../../helpers/TextHelper.js"
import { AREA } from "../../../../../constants/InitTypeConstants.js"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import { ACTIVITY_ICONS, ILUSTRATION_CONSTANS } from "../../../../../constants/ImageConstants.js"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers/index.js"
import swal from "sweetalert"
import { ActivityDetailHeader } from "../../../../../component/molecule/ActivityDetailHeader/ActivityDetailHeader.jsx"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import { FORM_NEED, getStringErrorMassage } from "../../../../helpers/StringCollection.js"
import { Form, Formik } from "formik"
import {
  ACTIVITY_METHODE_ENUM,
  ACTIVITY_STATUS_ENUM,
  BUYER_TYPE_ENUM,
  BUYER_TYPE_STRING,
  DATE_TYPE_ENUM,
  HARVEST_TYPE_ENUM,
  MACHINE_OWNER_TYPE_ENUM
} from "../../../../../constants/StringConstant.js"
import { ErpNumber } from "../../../../../component/atoms/ErpNumber/ErpNumber.jsx"

export const HarvestDetail = ({
  match: {
    params: { status, id, activity_id }
  }
}) => {
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const formRef = useRef()

  const { MasterData, General, Area } = useSelector((state) => state)
  const [detail, setDetail] = useState({})
  const [popUpInfoIndex, setPopUpInfoIndex] = useState(0)

  const [dataBuyer, setDataBuyer] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [popupContent, setPopupContent] = useState({})
  const [idDocument, setIdDocument] = useState(null)
  const [prefixMenu] = useState(null)
  const [showModalDialog, setShowModalDialog] = useState(false)
  const [swalMessage, setSwalMessage] = useState("")
  const [swalIcon, setSwallIcon] = useState("")
  const [showModalDialogErp, setShowModalDialogErp] = useState(false)
  const [showModalDialogRegion, setShowModalDialogRegion] = useState(false)
  const [initialValue] = useState({
    regional_id: ""
  })
  const [isValid, setIsValid] = useState(false)
  const [regionalValue, setRegionalValue] = useState("")

  const validationSchema = Yup.object().shape({
    regional_id: Yup.string().required(getStringErrorMassage("Regional ", FORM_NEED.harusDiIsi))
  })

  useEffect(() => {
    dispatch(getDetailHarvest(activity_id)).then()
  }, [])

  useEffect(() => {
    if (Area.data) {
      setDetail(Area.data)
    }
  }, [Area])

  useEffect(() => {
    let newStatus
    if (status === "active") {
      newStatus = "teregistrasi"
    }
    subHeader.setButton(null)
    subHeader.setBreadcrumbs([
      {
        pathname: RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("all"),
        title: "Lahan"
      },
      {
        pathname: RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("all"),
        title: newStatus
      },
      {
        pathname: "/lahan/list/" + status + "/" + id,
        title: detail?.area_information?.area_name
      },
      {
        title:
          detail?.harvest_type === HARVEST_TYPE_ENUM.PRODUCTION
            ? `Panen Ke-${detail?.activity_detail_response?.seq}`
            : "Panen"
      }
    ])
  }, [detail])

  useEffect(() => {
    if (detail) {
      handleDataBuyer()
    }
  }, [detail])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      if (General.actionInitType === "UPLOAD") {
        const indexIdDocument = idDocument ?? 0

        const imageId =
          prefixMenu && prefixMenu === AREA.ACTIVITY_HARVEST_ESTIMATED
            ? detail?.harvest_estimate?.image_and_id_activity[indexIdDocument]?.id
            : detail?.activity_detail_response?.image_and_id_activity[indexIdDocument]?.id

        const data = {
          id: imageId,
          url: MasterData.urlResponseUpload
        }

        dispatch(updateAreaActivityImage(data))
      } else if (General.actionInitType === AREA.UPDATE_ACTIVITY_IMAGE) {
        dispatch(getDetailHarvest(activity_id))
      } else if (General.actionInitType === AREA.PLANTING_SEASON_CONFIRMATION) {
        swal({
          text: swalMessage,
          icon: swalIcon
        })
        dispatch(getDetailHarvest(activity_id))
      } else if (
        General.loading === false &&
        General.actionInitType === AREA.APPROVE_ACTIVITY_TO_ERP
      ) {
        setShowModalDialogErp(false)
        swal({
          icon: ILUSTRATION_CONSTANS.SUCCESS_FORM,
          text: t("messages.response.activity_approve_to_erp_success")
        })
        dispatch(getDetailHarvest(activity_id))
      }
    }
  }, [General])
  const titleActivity = (
    <ActivityDetailHeader
      title={
        detail?.harvest_type === HARVEST_TYPE_ENUM.PRODUCTION
          ? `AKTIVITAS PANEN KE-${detail?.activity_detail_response?.seq}`
          : "AKTIVITAS PANEN"
      }
      icon={ACTIVITY_ICONS.IC_PANEN}
      rightContent={
        detail?.status_activity_enum === ACTIVITY_STATUS_ENUM.NEED_BA ? (
          <Status variant={detail?.status_activity_enum} text={detail?.status_activity_string} />
        ) : null
      }
      bottomContent={
        <ErpNumber
          noFaktur={detail?.no_faktur}
          noOrderSj={detail?.no_order_sj}
          noOrderSpk={detail?.no_order_spk}
          noOrderErp={detail?.no_order_erp}
          status={detail?.activity_type_enum}
        />
      }
    />
  )

  const openPopup = (content) => {
    setPopupContent(content)
    setShowPopup(true)
  }

  const bodyPopup = (
    <RowModule customColumnCss={"col-md-12 mb-2"}>
      <RowModule>
        <InlineText
          type={"between"}
          title={"ID Alsintan"}
          value={popupContent?.machine_code ?? "-"}
        />
      </RowModule>
      <RowModule>
        <InlineText
          type={"between"}
          title={"Nama Pemilik"}
          value={
            popupContent?.supplier_enum === "THIRD_PARTY" ||
            popupContent?.supplier_enum === "OTHER_FARMER" ||
            popupContent?.supplier_enum === "OTHER_MITRA" ||
            popupContent?.supplier_enum === "OTHER_VENDOR"
              ? "Pihak ke-3" + " - " + popupContent?.supplier_third_party_string
              : popupContent?.supplier_name ?? "-"
          }
        />
      </RowModule>
      <RowModule>
        <InlineText
          type={"between"}
          title={"Pemilik Alsintan"}
          value={popupContent?.owner_name ?? "-"}
        />
      </RowModule>
      <RowModule>
        <InlineText
          type={"between"}
          title={"Nama Alsintan"}
          value={popupContent?.machine_name ?? "-"}
        />
      </RowModule>
      <RowModule>
        <InlineText
          type={"between"}
          title={"Nomor Mesin"}
          value={
            popupContent?.engine_number === null || popupContent?.engine_number === ""
              ? "-"
              : popupContent?.engine_number
          }
        />
      </RowModule>
    </RowModule>
  )

  const overviewPerbedaan = (
    <Card className={"mb-4"}>
      <CardBody>
        <NoteView
          variant={
            detail?.status_activity_enum === ACTIVITY_STATUS_ENUM.NEED_BA ? "warning" : "completed"
          }
          title={
            detail?.status_activity_enum === ACTIVITY_STATUS_ENUM.NEED_BA
              ? "Terdapat perbedaan luas lahan yang dikerjakan alsintan dengan luas lahan manual. Harap membuat berita acara."
              : detail?.have_ba
              ? "Berita acara untuk perbedaan luas pengerjaan menggunakan alsintan telah dibuat."
              : ""
          }
        />
        <h4 className={"mb-6 text-uppercase"}>OVERVIEW PERBEDAAN LUAS PENGERJAAN</h4>
        <RowModule customColumnCss={"col-md-12 mb-2"}>
          <RowText
            label={"Luas Lahan (Manual)"}
            value={
              detail?.area_information?.area_land_manual
                ? toMeter(detail?.area_information?.area_land_manual)
                : "-"
            }
          />
          <RowText
            label={"Luas Lahan Dikerjakan (Alsintan)"}
            value={
              detail?.activity_detail_response?.worked_area
                ? toMeter(detail?.activity_detail_response?.worked_area)
                : "-"
            }
          />
          <RowText label={"Alasan"} value={checkValue(detail?.overview_different?.reason)} />
        </RowModule>
        <h5 className={"mb-4"}>Bukti Terupload</h5>
        <div className={"row"}>
          {detail?.overview_different?.images ? (
            detail?.overview_different?.images?.map((item, index) => {
              return (
                <div className={"col-md-3"} key={index}>
                  <DetailText
                    type="image"
                    value={item}
                    indexKey={index}
                    menu={AREA.ACTIVITY_HARVEST}
                    setIdDocument={setIdDocument}
                  />
                </div>
              )
            })
          ) : (
            <div className={"col"}>{"-"}</div>
          )}
        </div>
      </CardBody>
    </Card>
  )

  const informasiLahan = (
    <Card className={"mb-4"}>
      <CardBody>
        <h4 className={"mb-6 text-uppercase"}>INFORMASI LAHAN</h4>
        <div className={"row mb-2"}>
          <div className={"col-md-6"}>
            <RowText label={"Lahan"} value={detail?.area_information?.area_name ?? "-"} />
          </div>
          <div className={"col-md-6"}>
            <RowText
              label={"Luas Lahan (Manual)"}
              value={
                detail?.area_information?.area_land_manual
                  ? toMeter(detail?.area_information?.area_land_manual)
                  : "-"
              }
            />
          </div>
          {detail?.harvest_type === HARVEST_TYPE_ENUM.PRODUCTION && (
            <div className={"col-md-6"}>
              <RowText
                label={t("label.block_name")}
                value={checkValue(detail?.area_information.block_code)}
              />
            </div>
          )}
          <div className={"col-md-6"}>
            <RowText
              label={"Petani"}
              value={
                detail?.area_information?.farmer_name
                  ? `${detail?.area_information?.farmer_name} (${detail?.area_information?.farmer_code})`
                  : "-"
              }
              hasIcon={detail?.area_information?.has_kur}
              icon={detail?.area_information?.bank_image}
            />
          </div>
          <div className={"col-md-6"}>
            <RowText
              label={"Luas Lahan (Polygon)"}
              value={
                detail?.area_information?.area_land_polygon
                  ? toMeter(detail?.area_information?.area_land_polygon)
                  : "-"
              }
            />
          </div>
          <div className={"col-md-6"}>
            <RowText
              label={"Mitra"}
              value={
                detail?.area_information?.mitra_name
                  ? `${detail?.area_information?.mitra_name} (${detail?.area_information?.mitra_code})`
                  : "-"
              }
            />
          </div>
          <div className={"col-md-6"}>
            <RowText
              label={"Jenis Tanaman"}
              value={checkValue(detail?.activity_detail_response?.plant_type_name)}
            />
          </div>
          <div className={"col-md-6"}>
            <RowText
              label={"Lokasi Lahan"}
              value={checkValue(detail?.area_information?.full_address)}
            />
          </div>
          <div className={"col-md-6"}>
            <RowText
              label={"Varietas Tanaman"}
              value={checkValue(detail?.area_information?.variety_name)}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  )

  const detailActivitas = (
    <Card className={"mb-4"}>
      <CardBody>
        <h4 className={"mb-6 mt-6 text-uppercase"}>Detail Aktivitas</h4>
        <RowModule customColumnCss={"col-md-6 mb-2"}>
          <RowText
            label={t("label.created_by")}
            value={detail?.activity_detail_response?.submitted_by ?? "-"}
          />
          <RowText
            label={t("label.age_at_activity")}
            value={
              isNaN(detail?.activity_detail_response?.hst)
                ? "-"
                : `${detail?.activity_detail_response?.hst} HST`
            }
          />
          <RowText
            label={t("label.created_date")}
            value={
              detail?.activity_detail_response?.submitted_date
                ? timeStampToDate(
                    detail?.activity_detail_response?.submitted_date,
                    DATE_TYPE_ENUM.ISO_DATETIME_STRAP
                  )
                : "-"
            }
          />
          <RowText
            label={t("label.harvest_method")}
            value={checkValue(detail?.activity_detail_response?.activity_methode_string)}
          />
          <RowText
            label={t("label.activity_date")}
            value={
              detail?.harvest_type === HARVEST_TYPE_ENUM.PRODUCTION
                ? timeStampToDate(
                    checkValue(detail?.activity_detail_response.activity_date),
                    DATE_TYPE_ENUM.ISO_DATE_STRAP
                  )
                : checkDateRangeValue(detail?.start_date, detail?.end_date)
            }
          />
          <RowText
            label={
              detail?.activity_detail_response?.activity_methode_enum ===
              ACTIVITY_METHODE_ENUM.USING_TOOL
                ? t("label.alsintan_land_area_worked")
                : t("label.manual_land_area_worked")
            }
            value={
              detail?.activity_detail_response?.worked_area
                ? toMeter(detail?.activity_detail_response?.worked_area)
                : "-"
            }
          />
        </RowModule>

        {detail?.activity_detail_response?.activity_methode_enum === ACTIVITY_METHODE_ENUM.MANUAL
          ? null
          : detail?.tools?.map((item, index) => {
              return (
                <Card className={"mb-10"} bg={"secondary"} key={index}>
                  <CardBody>
                    <h5 className={"mb-6"}>Alsintan {index + 1}</h5>
                    <RowModule customColumnCss={"col-md-6 mb-2"}>
                      <RowText
                        label={t("label.machine_owner")}
                        value={
                          item?.supplier_enum === MACHINE_OWNER_TYPE_ENUM.THIRD_PARTY ||
                          item?.supplier_enum === MACHINE_OWNER_TYPE_ENUM.OTHER_FARMER ||
                          item?.supplier_enum === MACHINE_OWNER_TYPE_ENUM.OTHER_MITRA ||
                          item?.supplier_enum === MACHINE_OWNER_TYPE_ENUM.OTHER_VENDOR
                            ? "Pihak ke-3" + " - " + item?.supplier_third_party_string
                            : item?.supplier_name ?? "-"
                        }
                      />
                      <RowText
                        label={t("label.operator")}
                        value={
                          item?.operators && item.operators.length
                            ? item.operators.map((e) => e.operator_name).join(", ")
                            : "-"
                        }
                      />
                      <RowText
                        label={t("label.mahcine_owner_name")}
                        value={checkValue(item?.owner_name)}
                      />
                      {item?.use_implement ? (
                        <RowText
                          label={t("label.implement")}
                          value={checkValue(item?.implement_name)}
                        />
                      ) : (
                        <></>
                      )}
                      <RowText label={t("label.alsintan")} value={checkValue(item?.machine_name)} />
                    </RowModule>
                    <a
                      className="hover"
                      style={{
                        textDecorationLine: "underline",
                        color: "#5EC8F2"
                      }}
                      onClick={() => {
                        openPopup(item)
                      }}>
                      Lihat Detail
                    </a>
                  </CardBody>
                </Card>
              )
            })}

        <div className={"my-10"}>
          <h4 className={"mb-6"}>CATATAN</h4>
          <div className={"row mb-4"}>
            {detail?.activity_detail_response?.images &&
              detail?.activity_detail_response?.images?.map((item, index) => {
                return (
                  <div className={"col-md-3"} key={index}>
                    <DetailText
                      type="image"
                      value={item}
                      indexKey={index}
                      menu={AREA.ACTIVITY_HARVEST}
                      setIdDocument={setIdDocument}
                    />
                  </div>
                )
              })}
          </div>
          <span className="font-weight-bold text-dark-50 font-size-sm">
            {checkValue(detail?.activity_detail_response?.note)}
          </span>
        </div>
      </CardBody>
    </Card>
  )

  const estimated = (
    <Card className={"mb-4"}>
      <CardBody>
        <h4 className={"mb-6"}>DATA ESTIMASI PANEN</h4>
        <RowModule customColumnCss={"col-md-6 mb-2"}>
          <RowText
            label={t("label.request_id")}
            value={checkValue(detail?.harvest_estimate?.id_pengajuan)}
          />
          <RowText
            label={t("label.planting_area")}
            value={
              detail?.harvest_estimate?.planting_area
                ? toMeter(detail?.harvest_estimate?.planting_area)
                : "-"
            }
          />
          <RowText
            label={t("label.submitted_by")}
            value={checkValue(detail?.harvest_estimate?.submitted_by)}
          />
          <RowText
            label={t("label.harvest_estimation")}
            value={checkDateRangeValue(
              detail?.harvest_estimate?.start_date,
              detail?.harvest_estimate?.end_date
            )}
          />
          <RowText
            label={t("label.survey_date")}
            value={
              detail?.harvest_estimate?.survey_date
                ? detail?.harvest_estimate?.survey_date +
                  ` (${checkValue(detail?.harvest_estimate?.hst_survey, "HST")})`
                : "-"
            }
          />
          <RowText
            label={t("label.harvest_age_estimation")}
            value={checkValue(detail?.harvest_estimate?.hst_harvest, "HST")}
          />
        </RowModule>
      </CardBody>
    </Card>
  )

  const columnsHarvest = [
    {
      dataField: "no",
      text: "No",
      headerClasses: "bg-gray"
    },
    {
      dataField: "parameter",
      text: "Parameter",
      headerClasses: "bg-gray"
    },
    detail?.harvest_estimate || detail?.harvest_type === HARVEST_TYPE_ENUM.FREE_MARKET
      ? {
          dataField: "harvest_estimate",
          text: "Estimasi Panen",
          headerClasses: "bg-gray"
        }
      : { headerClasses: "bg-gray" },
    {
      dataField: "harvest",
      text: "Realisasi Panen",
      headerClasses: "bg-gray"
    }
  ]

  const dataHarvest = () => {
    let data = [
      {
        no: "1",
        parameter: "Umur Panen",
        harvest_estimate: checkValue(
          detail?.harvest_data && detail?.harvest_data.hst_estimate,
          " HST"
        ),
        harvest: checkValue(detail?.harvest_data && detail?.harvest_data.hst_harvest, " HST")
      },
      detail?.harvest_type === HARVEST_TYPE_ENUM.PRODUCTION
        ? {
            no: "2",
            parameter: "Jumlah Karung",
            harvest_estimate: "-",
            harvest: checkValue(detail?.harvest_data && detail?.harvest_data.total_sacks)
          }
        : {
            no: "2",
            parameter: "Kadar Air",
            harvest_estimate: "-",
            harvest: checkValue(detail?.harvest_data && detail?.harvest_data?.water_contain, "%")
          }
    ]
    detail?.harvest_type === HARVEST_TYPE_ENUM.FREE_MARKET &&
      data.push({
        no: 3,
        parameter: "Kotoran",
        harvest_estimate: "-",
        harvest: checkValue(detail?.harvest_data && detail?.harvest_data.waste, "%")
      })
    data.push({
      no: detail?.harvest_type === HARVEST_TYPE_ENUM.PRODUCTION ? 3 : 4,
      parameter: "Bobot",
      harvest_estimate: checkValue(detail?.harvest_estimate?.weight, " Kg"),
      harvest: checkValue(
        detail?.harvest_data && toThousandConvert(detail?.harvest_data.weight),
        " Kg"
      )
    })
    detail?.harvest_type === HARVEST_TYPE_ENUM.PRODUCTION &&
      data.push({
        no: 4,
        parameter: "Timbang Lapang Sorting",
        harvest_estimate: "-",
        harvest: checkValue(detail?.harvest_data && detail?.harvest_data.weight_slip, "Karung")
      })

    return data
  }

  const harvest = (
    <>
      {General.loading ? (
        <TableSkeleton column={4} row={3} />
      ) : (
        <Card className={"mb-4"}>
          <CardBody>
            <h4 className={"mb-6"}>DATA PANEN</h4>
            {detail?.harvest_type === HARVEST_TYPE_ENUM.PRODUCTION && (
              <RowModule customColumnCss={"col-md-6 mb-2"}>
                <RowText
                  label={t("label.delivery_destination")}
                  value={checkValue(detail?.harvest_data?.delivery_destination)}
                />
                <RowText
                  label={t("label.harvest_hours")}
                  value={checkValue(detail?.harvest_data?.harvest_date, "WIB")}
                />
              </RowModule>
            )}
            <div className={"border rounded table-small-padding mb-10"}>
              <BootstrapTable
                condensed
                bordered={false}
                columns={columnsHarvest}
                keyField={"no"}
                data={dataHarvest()}
              />
            </div>
            {detail?.harvest_type === HARVEST_TYPE_ENUM.PRODUCTION && (
              <Card className={"mb-10"} bg={"secondary"}>
                <CardBody>
                  <h4 className={"mb-6"}>SURAT JALAN</h4>
                  <RowModule customColumnCss={"col-md-6 mb-2"}>
                    <RowText
                      label={t("label.sj_number")}
                      value={checkValue(detail?.harvest_data?.surat_jalan.surat_jalan_number)}
                    />
                    <RowText
                      label={t("label.plat_number")}
                      value={checkValue(detail?.harvest_data?.surat_jalan.plat_number)}
                    />
                    <RowText
                      label={t("label.driver_name")}
                      value={checkValue(detail?.harvest_data?.surat_jalan.driver_name)}
                    />
                    <RowText
                      label={t("label.phone_number")}
                      value={checkValue(detail?.harvest_data?.surat_jalan.phone_number)}
                    />
                  </RowModule>
                </CardBody>
              </Card>
            )}
          </CardBody>
          <div className="separator separator-solid" />
        </Card>
      )}
    </>
  )

  const uiTotal = ({ total_price }) => {
    return (
      <>
        <div className={"d-flex"}>
          <span className={"mr-8"}>{toRupiah(total_price) ?? "-"}</span>
        </div>
      </>
    )
  }
  const uiDetail = ({ no }) => {
    return (
      <>
        <div className={"d-flex"}>
          <a
            className={"link"}
            onClick={() => {
              setShowModal(true)
              setPopUpInfoIndex(no - 1)
            }}>
            Detail
          </a>
        </div>
      </>
    )
  }

  const uiWeight = (e) => {
    return <>{checkValue(toThousandConvert(e?.weight), "Kg")}</>
  }
  const uiPrice = (e) => {
    return <>{checkValue(toRupiah(e?.price_per_kg))}</>
  }
  const uiName = (e) => {
    return <>{checkValue(e?.buyer_id.name)}</>
  }
  const uiType = (e) => {
    return <>{checkValue(typeName(e?.buyer_id))}</>
  }

  const typeName = (data) => {
    if (data?.type) {
      if (data?.type === BUYER_TYPE_ENUM.INDIVIDUAL) {
        return BUYER_TYPE_STRING.INDIVIDUAL
      } else if (data?.type === BUYER_TYPE_ENUM.FACTORY) {
        return BUYER_TYPE_STRING.FACTORY
      } else if (data?.type === BUYER_TYPE_ENUM.FARMER || data?.is_self_buying) {
        return BUYER_TYPE_STRING.FARMER
      } else {
        return "-"
      }
    } else {
      return "-"
    }
  }
  const columnsBuyer = [
    {
      dataField: "no",
      text: "No",
      headerClasses: "bg-gray"
    },
    {
      dataField: "name",
      text: "Nama",
      headerClasses: "bg-gray",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiName
      }
    },
    {
      dataField: "type",
      text: "Tipe",
      headerClasses: "bg-gray",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiType
      }
    },
    {
      dataField: "weight",
      text: "Bobot",
      headerClasses: "bg-gray",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiWeight
      }
    },
    {
      dataField: "price_per_kg",
      text: "Harga/Kg",
      headerClasses: "bg-gray",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiPrice
      }
    },
    {
      dataField: "total_price",
      text: "Total",
      headerClasses: "bg-gray",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiTotal
      }
    },
    {
      dataField: "total_price",
      text: "",
      headerClasses: "bg-gray",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiDetail
      }
    }
  ]

  const handleDataBuyer = () => {
    let data = []
    detail?.buyer_list?.map((item, index) => {
      return data.push({
        ...item,
        no: index + 1
      })
    })
    setDataBuyer(data)
  }

  const buyer = (
    <>
      {General.loading ? (
        <TableSkeleton column={4} row={3} />
      ) : (
        <Card className={"mb-4"}>
          <CardBody>
            <h4 className={"mb-6"}>DATA PEMBELI</h4>
            {dataBuyer.length === 1 &&
              dataBuyer[0]?.buyer_id &&
              dataBuyer[0].buyer_id.is_self_buying && (
                <NoteView variant={"success"} title={t("messages.alert.buyed_all_by_farmer")} />
              )}
            <div className={"border rounded table-small-padding"}>
              <BootstrapTable
                condensed
                bordered={false}
                columns={columnsBuyer}
                keyField={"no"}
                data={dataBuyer}
              />
            </div>
          </CardBody>
        </Card>
      )}
    </>
  )

  const styleImage = {
    width: "100px",
    height: "100px"
  }

  const modalBody = (index) => {
    return (
      <>
        <div className={"mb-10 px-6"}>
          <h5 className={"mb-6"}>Data Pembeli</h5>
          <RowModule customColumnCss={"col-md-12 mb-1"}>
            <RowModule>
              <InlineText
                type={"between"}
                title={t("label.buyer_type")}
                value={checkValue(typeName(dataBuyer[index]?.buyer_id))}
              />
            </RowModule>
            <RowModule>
              <InlineText
                type={"between"}
                title={t("label.buyer_name")}
                value={checkValue(dataBuyer[index]?.buyer_id?.name)}
              />
            </RowModule>
            <RowModule>
              <InlineText
                type={"between"}
                title={t("label.phone")}
                value={checkValue(dataBuyer[index]?.buyer_id?.no_telp)}
              />
            </RowModule>
            <RowModule>
              <InlineText
                type={"between"}
                title={t("label.address")}
                value={checkValue(dataBuyer[index]?.buyer_id?.address)}
              />
            </RowModule>
          </RowModule>
        </div>
        <div className={"px-6"}>
          <h5 className={"mb-6"}>Data Hasil Panen Terjual</h5>
          <RowModule customColumnCss={"col-md-12 mb-2"}>
            {typeName(dataBuyer[index]?.buyer_id) !== "Petani" && (
              <RowModule>
                <InlineText
                  type={"between"}
                  title={"Harga per Kg"}
                  value={checkValue(toRupiah(dataBuyer[index]?.price_per_kg))}
                />
              </RowModule>
            )}
            <RowModule>
              <InlineText
                type={"between"}
                title={"Bobot"}
                value={checkValue(toThousandConvert(dataBuyer[index]?.weight), "Kg")}
              />
            </RowModule>
            <RowModule>
              <InlineText
                type={"between"}
                title={"Jumlah Karung"}
                value={checkValue(dataBuyer[index]?.total_sacks, "Sak")}
              />
            </RowModule>
            {typeName(dataBuyer[index]?.buyer_id) !== "Petani" && (
              <RowModule>
                <InlineText
                  type={"between"}
                  title={"Total Harga"}
                  value={checkValue(toRupiah(dataBuyer[index]?.total_price))}
                />
              </RowModule>
            )}
            <div className={"my-10"}>
              <h4 className={"mb-6"}>Bukti Pembayaran</h4>
              <div className={"d-flex"} style={{ gap: "8px" }}>
                {dataBuyer[index]?.images
                  ? dataBuyer[index]?.images?.map((item, index) => {
                      return (
                        <img
                          src={item}
                          className={"cursor-pointer"}
                          alt={item}
                          key={index}
                          style={styleImage}
                          onClick={() => window.open(item, "_blank", "noopener,noreferrer")}
                        />
                      )
                    })
                  : "-"}
              </div>
            </div>
          </RowModule>
        </div>
      </>
    )
  }

  const modulPopUpInfoDetail = (
    <>
      <Popup
        show={showModal}
        title={"DATA PEMBELI"}
        bodyCustom={modalBody(popUpInfoIndex)}
        textButton="Oke"
        onClick={() => {
          setShowModal(false)
        }}
        type={"nonImage"}
      />
    </>
  )

  const weightSlip = (
    <>
      <Card className={"mb-4"}>
        <CardBody>
          <h4 className={"mb-6 mt-6 text-uppercase"}>Data Slip Timbang</h4>
          <NoteView variant={"success"} title={t("messages.alert.note_slip_weight")} />
          <RowModule customColumnCss={"col-md-6 mb-2"}>
            <RowText
              label={t("label.weight_slip_number")}
              value={checkValue(detail?.weight_slip?.weight_slip_no)}
            />
            <RowText
              label={t("label.pass_weight")}
              value={checkValue(detail?.weight_slip?.weight_passed)}
            />
            <RowText label={t("label.netto")} value={checkValue(detail?.weight_slip?.netto)} />
            <RowText
              label={t("not_passed_weight")}
              value={checkValue(detail?.weight_slip?.weight_not_passed)}
            />
          </RowModule>
          <div className={"my-10"}>
            <h4 className={"mb-6"}>FOTO SLIP TIMBANG</h4>
            <div className={"row mb-4"}>
              {detail?.weight_slip?.images &&
                detail?.weight_slip?.images?.map((item, index) => {
                  return (
                    <div className={"col-md-3"} key={index}>
                      <DetailText
                        type="image"
                        value={item}
                        indexKey={index}
                        menu={AREA.ACTIVITY_HARVEST}
                        setIdDocument={setIdDocument}
                      />
                    </div>
                  )
                })}
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  )

  return (
    <>
      {modulPopUpInfoDetail}
      <PopupQuestion
        onCancel={() => setShowModalDialogRegion(false)}
        textCancel={t("button.cancel")}
        textOk={t("button.continue")}
        title={t("messages.title.activity_select_regional")}
        onOk={() => {
          setShowModalDialogRegion(false)
          setShowModalDialogErp(true)
        }}
        disable={!isValid}
        show={showModalDialogRegion}
        bodyCustom={
          <Formik
            enableReinitialize
            initialValues={initialValue}
            innerRef={formRef}
            validationSchema={validationSchema}>
            {() => (
              <Form id={"formRegion"} className={"form"}>
                <div className={"mt-4"}>
                  <RegionSelect setIsValid={setIsValid} setRegionalValue={setRegionalValue} />
                </div>
              </Form>
            )}
          </Formik>
        }
      />
      <PopupQuestion
        onCancel={() => setShowModalDialogErp(false)}
        textCancel={t("button.cancel")}
        textOk={t("button.continue")}
        title={t("messages.title.activity_send_to_erp")}
        onOk={() => {
          dispatch(
            activitySendToErp(activity_id, {
              regional_id: regionalValue
            })
          )
          setShowModalDialogErp(false)
        }}
        show={showModalDialogErp}
        bodyCustom={
          <RowModule>
            <p className={"swal-text p-4"}>
              {t("messages.confirmation.activity_approve_to_erp_confirmation")}
            </p>
          </RowModule>
        }
      />
      <PopupQuestion
        onCancel={() => setShowModalDialog(false)}
        textCancel={t("button.cancel")}
        textOk={t("button.continue")}
        title={t("messages.title.planting_season_confirmation")}
        onOk={() => {
          dispatch(confirmationSeasonPlanting(detail?.activity_detail_response?.area_id))
          setShowModalDialog(false)
          setSwalMessage("Musim tanam berhasil dikonfirmasi.")
          setSwallIcon(toAbsoluteUrl(ILUSTRATION_CONSTANS.PLANTING_SUCCESS))
        }}
        show={showModalDialog}
        bodyCustom={
          <RowModule>
            <p className={"swal-text p-4"}>
              {detail.have_ba
                ? t("messages.confirmation.planting_season_confirmation_need_ba")
                : t("messages.confirmation.planting_season_confirmation")}
            </p>
          </RowModule>
        }
      />
      {detail?.harvest_type === HARVEST_TYPE_ENUM.PRODUCTION && (
        <NoteView variant={"success"} title={t("messages.alert.note_harvest_report_production")} />
      )}
      {titleActivity}
      {detail?.status_activity_enum === ACTIVITY_STATUS_ENUM.NEED_BA ? overviewPerbedaan : null}
      {informasiLahan}
      {detail?.harvest_type === HARVEST_TYPE_ENUM.FREE_MARKET && estimated}
      {detailActivitas}
      {harvest}
      {detail?.harvest_type === HARVEST_TYPE_ENUM.FREE_MARKET && buyer}
      {detail?.harvest_type === HARVEST_TYPE_ENUM.PRODUCTION && detail?.weight_slip && weightSlip}

      <Popup
        show={showPopup}
        title={`DETAIL ALSINTAN ${
          popupContent?.supplier_name ? popupContent?.supplier_name?.toUpperCase() : ""
        }`}
        bodyCustom={bodyPopup}
        textButton="Oke"
        onClick={() => {
          setShowPopup((Popup) => {
            !Popup
          })
        }}
      />
    </>
  )
}
